import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Select } from '@chakra-ui/react'
import { createLetter, updateLetter } from '../../../services/dataApi/dataProvider';
import Cookies from 'js-cookie';
import { useToast } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { showErrorMessage } from '../../../common/CommonFunctions';




const CreateLetter = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false)
  const [newLetter, setNewLetter] = useState({
    type: location?.state?.letter ? location.state.letter.type : '',
    heading: location?.state?.letter ? location?.state?.letter?.heading : '',
    text: location?.state?.letter ? location?.state?.letter?.text : '',
    lang: location?.state?.lang ? location?.state?.lang : 'en-in',
  })
  const toast = useToast()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewLetter((inputData) => ({ ...inputData, [name]: value }))

  }
  const validationSchema = () => {
    if (!newLetter.type) {
      showErrorMessage('Error', 'Please select a valid Letter type', 'error', toast)
    }
    else if (!newLetter.heading) {
      showErrorMessage('Error', 'Please Enter Letter heading', 'error', toast)
    }
    else if (!newLetter.text) {
      showErrorMessage('Error', 'Please Enter Letter Content', 'error', toast)
    }
    else {
      return true
    }
  }
  return (
    <div className='h-full flex'>
      <div className='basis-1/2 flex h-screen'>
        <img src={require('../../../assets/letters.jpg')} alt="" />
      </div>
      <div className='basis-1/2 h-screen'>
        <div className='flex w-full mb-5 mt-5 justify-center shadow-xl'>
          <Formik
            initialValues={{
              type: '',
              heading: '',
              text: '',
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              const validation = validationSchema()
              if (validation) {
                const token = Cookies.get('token')
                const headers = new Headers();
                let response
                headers.append("X-ZUMO-AUTH", token);
                const formdata = new FormData();
                formdata.append("audioText", "null");
                formdata.append("heading", newLetter.heading);
                formdata.append("text", newLetter.text);
                formdata.append("type", newLetter.type);
                if (location?.state?.letter && location?.state?.lang) {
                  formdata.append("lang", location?.state?.lang)
                  const requestOptions = {
                    method: "PUT",
                    headers,
                    body: formdata,
                    redirect: "follow"
                  }
                  setLoader(true)
                  response = await updateLetter(location?.state?.letter?.sequenceNumber, requestOptions)
                  setLoader(false)
                }
                else {
                  const requestOptions = {
                    method: "POST",
                    headers,
                    body: formdata,
                    redirect: "follow"
                  };
                  setLoader(true)
                  response = await createLetter(requestOptions)
                  setLoader(false)
                }
                if (response) {
                  if (response.success) {
                    if (location?.state?.letter?.sequenceNumber) {
                      showErrorMessage('Successfull', 'Letter has been updated successfully.', 'success', toast)
                    }
                    else {
                      showErrorMessage('Successfull', 'Letter has been created successfully.', 'success', toast)
                    }
                  }
                  else {
                    showErrorMessage('Error', 'Letter was not created!', 'error', toast)
                  }
                }
                else {
                  showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
                }
                setNewLetter({
                  type: '',
                  heading: '',
                  text: '',
                })
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form className='flex mt-8 w-11/12 h-screen'>
                <div className='w-full'>
                  <div className='w-full'>
                    {
                      location?.state?.letter?.type
                        ?
                        <Select name='type' placeholder={`${location.state.letter.type}`.toLocaleUpperCase()} disabled onChange={handleChange} bg='white'>
                        </Select>
                        :
                        <Select name='type' placeholder='Select Letter type' onChange={handleChange} bg='white'>
                          <option value='reflective'>Reflective</option>
                          <option value='challenging'>Challenging</option>
                        </Select>
                    }
                  </div>
                  <div className='w-full mt-5'>
                    <Field className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ' type="text" name='heading' value={newLetter.heading} placeholder="Heading" aria-label="Last name" onChange={handleChange} />
                  </div>
                  <div className='w-full mt-5'>
                    <textarea rows={18} cols={40} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ' type="text" name='text' value={newLetter.text} placeholder="Type here" aria-label="heading" onChange={handleChange} />
                  </div>
                  <div className='w-full mt-5 mb-5'>
                    {
                      loader ? <button type="submit" className='w-full bg-blue-300 h-10 rounded-md cursor-not-allowed' disabled>
                        <CircularProgress
                          size={28}
                          sx={{
                            color: 'white',
                          }}
                        />
                      </button>
                        :
                        <button type="submit" className='w-full bg-blue-500 h-10 rounded-md text-white text-xl'>{location?.state?.letter?.sequenceNumber ? 'Update' : 'Create'}</button>
                    }
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default CreateLetter