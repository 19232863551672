import React, { useEffect, useState } from 'react'
import { IoMdHome } from "react-icons/io";
import { BsChatSquareText } from "react-icons/bs";
import { FaUsers } from "react-icons/fa6";
import { FaRegEnvelopeOpen } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { AUTH_ENDPOINT } from '../config';
import { MdLogout } from "react-icons/md";
import Cookies from 'js-cookie';
import { headerProvider, showErrorMessage } from '../common/CommonFunctions';
import Avatar from '@mui/material/Avatar';
import { useToast } from '@chakra-ui/react'
import { ReactComponent as Logo } from '../assets/logo.svg'
import { logout } from '../services/authApi/authProvider';
import { isExpired, decodeToken } from "react-jwt";







const Sidebar = () => {
    const toast = useToast()
    let location = useLocation();
    const navigate = useNavigate();
    const [adminProfile, setAdminProfile] = useState({})


    useEffect(() => {
        const credential = localStorage.getItem('credential')
        const myDecodedToken = decodeToken(credential);
        setAdminProfile(myDecodedToken)
    }, [])
    return (
        <>
            <div className='h-screen border-r flex justify-between flex-col'>
                <div className='flex flex-col'>
                    <div className='mt-5 h-20 flex justify-center items-center gap-4 p-4 ml-2'>
                        <div className='basis-1/4'>
                            <Avatar
                                alt={adminProfile.picture}
                                src={adminProfile.picture}
                                sx={{ width: 60, height: 60 }}
                            />
                        </div>
                        <div className='basis-3/4'>
                            <h1 className='text-lg font-medium'>{adminProfile.name}</h1>
                        </div>
                    </div>
                    <div className='gap-3 flex-col flex justify-center items-center mt-5'>
                        <Link to='/dashboard'>
                            <div className={`${location.pathname === '/dashboard' ? 'bg-slate-100' : 'white'} flex w-64 h-14 gap-8 items-center cursor-pointer hover:bg-slate-100 rounded-md p-4`}>
                                <IoMdHome size={30} />
                                <p className='text-2xl font-semibold'>Home</p>
                            </div>
                        </Link>
                        <Link to='/conversations'>
                            <div className={`${location.pathname === '/conversations' ? 'bg-slate-100' : 'white'} flex w-64 h-14 gap-8 items-center cursor-pointer hover:bg-slate-100 rounded-md p-4`}>
                                <BsChatSquareText size={30} />
                                <p className='text-2xl font-semibold'>Conversations</p>
                            </div>
                        </Link>
                        {/* <Link to='/therapist'> */}
                            <div className={`${location.pathname === '/therapist' ? 'bg-slate-100' : 'white'} flex w-64 h-14 gap-8 items-center hover:bg-slate-100 rounded-md p-4`}>
                                <FaUsers size={30} />
                                <p className='text-2xl font-semibold'>Therapist</p>
                            </div>
                        {/* </Link> */}
                        <Link to='/letters'>
                            <div className={`${location.pathname === '/letters' ? 'bg-slate-100' : 'white'} flex w-64 h-14 gap-8 items-center hover:bg-slate-100 rounded-md p-4`}>
                                <FaRegEnvelopeOpen size={30} />
                                <p className='text-2xl font-semibold'>Letters</p>
                            </div>
                        </Link>
                        <div
                            className={`cursor-pointer flex w-64 h-14 gap-8 items-center hover:bg-slate-100 rounded-md p-4`}
                            onClick={async () => {
                                try {
                                    const headers = headerProvider()
                                    const response = await logout(headers)
                                    if (response) {
                                        if (response.success) {
                                            Cookies.remove('token')
                                            navigate('/')
                                            showErrorMessage('Logged Out', response.response, 'success', toast)
                                        }
                                        else {
                                            showErrorMessage('Failed', response.response, 'error', toast)
                                        }
                                    }
                                    else {
                                        showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
                                    }
                                } catch (error) {
                                    console.log("This is the error", error);
                                }

                            }}
                        >
                            <MdLogout size={30} />
                            <p className='text-2xl font-semibold'>Logout</p>
                        </div>
                    </div>
                    <div className='mt-24 w-32 self-center'>
                        <Logo />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar